// Dependencies
import React from "react";
import {gsap} from 'gsap';

// Components
import Icon from "./icons/";
import SingleArrow from "./icons/singleArrow";
import Check from "./icons/check";

let usersModified = []

class SelectMultiple extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      items:
        props.itemsSelected !== undefined && props.itemsSelected.length > 0
          ? props.itemsSelected
          : [],
      showTooltip: false,
      inputValue: {},
      canEdit: false,
    };
    this.checkIconButton = React.createRef();
    this.editIconButton = React.createRef();
  }

  componentDidMount() {
    const body = document.querySelector('body')
    // body.style.overflow = 'hidden'
  }

  componentWillUnmount() {
    const body = document.querySelector('body')
    body.style.overflow = 'visible'
  }

  componentDidUpdate(prevProps) {
    if (this.props.itemsSelected !== prevProps.itemsSelected) {
      this.setState({ items: this.props.itemsSelected });
    }
  }

  _openOptionsModal(e) {
    if (!this.props.disabled) {
      this.props.openOptions(e);

      this.dropdown();
    }
  }

  dropdown() {
    if (!this.containerElement.classList.contains("open")) {
      this.optionsElement.style.height =
        this.props.options.filter((item) => item.id !== "").length * 41 +
        32 +
        "px";

      this.containerElement.style.zIndex = 10;
      this.containerElement.classList.add("open");
      this.iconElement.classList.add("rotate");
      if (
        this.props.options.filter((item) => item.id !== "").length * 41 + 32 >
        217
      ) {
        this.optionsElement.classList.add("scroll");
      }
    } else {
      this.optionsElement.style.height = 0;
      this.containerElement.classList.remove("open");

      this.iconElement.classList.remove("rotate");
    }
  }

  _selectOption(e, item, state, keepOpenDropdown) {
    e.stopPropagation();

    if (this.props.multiple) {
      let found = state.items.filter((element) => element.value === item.value);
      if (found.length > 0) {
        let allItems = state.items.filter((element) => element.id !== item.id);
        this.setState({ items: allItems });
        this.props.getOptions(allItems);
      } else {
        this.setState({ items: [...state.items, item] });
        this.props.getOptions([...state.items, item]);
      }
    } else {
      this.props.getOptions({ item });

      !keepOpenDropdown && this.dropdown();
    }
  }

  /*
   * _handleTooltip(e, index) {
   *   e.stopPropagation();
   *   this.state.showTooltip === false && this.setState({showTooltip: index})
   *   let body = document.body;
   *   body.addEventListener('click', e => {
   *     this.setState({ showTooltip: false })
   *   });
   * }
   */

  _handleTooltip(e) {
    e.stopPropagation();

    if (this.state.showTooltip !== false) {
      let modalContent = document.querySelector(".modal-content");
      if (modalContent) {
        let modalBody = document.querySelector(".modal-body");
        modalBody.style.paddingTop = "0px";
      }
      this.setState({ showTooltip: false });
    } else {
      this.setState({ showTooltip: true });
      let modalContent = document.querySelector(".modal-content");
      if (modalContent) {
        setTimeout(() => {
          this.modalPaddingTop();
        }, 0);
      }
    }
  }

  modalPaddingTop() {
    let tooltip = document.querySelector(".container-tooltip");
    let wrapperHelper = document.querySelector(".wrapper-helper");
    let containerTooltip = document.querySelector(".container-tooltip");
    let containerTootlipHeight = containerTooltip.offsetHeight + 36;
    let containerTootlipTop = containerTooltip.getBoundingClientRect().y;
    let modalContent = document.querySelector(".modal-content");
    let modalContentTop = modalContent.getBoundingClientRect().y;
    let dif = containerTootlipTop - (modalContentTop + 112);

    if (dif < 0) {
      let difPositive = -dif;
      let modalBody = document.querySelector(".modal-body");
      modalBody.style.paddingTop = `${difPositive}px`;
    }
  }

  _getLanguageLegible(language) {
    switch (language) {
      case "es-ES":
        return "Español";
      case "en-US":
        return "English";
      default:
        return "English";
    }

  }


  // Option type input

  _editInput(e, item, index) {
    let button = e.currentTarget
    let input = button.previousSibling
    let parent = e.currentTarget.closest('.options-multiple-select')
    let allInputs = [...parent.querySelectorAll('.options-multiple-select__input')]

    allInputs.forEach(input => input.disabled = true)

    input.disabled = false
    input.focus()

    gsap.to('.js-edit-button', {
      opacity: 0,
      duration: 0.3,
      onComplete: (e) => {
        this.setState({ 
          inputValue : {
            value: input.value, 
            index 
          } ,
          canEdit: true
        })
        gsap.from('.js-check-button', {
          opacity: 0,
          duration: 1,
          onComplete: (e) => {
          }
        })
      }
    })


  }

  _inputOnchange(e, index) {
    this.setState({ inputValue : {index, value: e.target.value} })
  }

  _inputValue(item, index) {

    if (this.state.inputValue.index == index) {
      return this.state.inputValue.value

    } else {
      if (usersModified.length > 0) {

        let uid = usersModified.findIndex(user => user.id === item.id)

        if (uid !== -1) {
          return usersModified[uid].value
        }else {
          return item.value
        }

      } else {
        return item.value

      }
    }

  }

  _confirmUserChanged(e, item, state) {

    e.stopPropagation()


    let button = e.currentTarget
    let input = button.previousSibling
    input.disabled = true
    input.blur()

    let newItem = {...item, value: state.inputValue.value ? state.inputValue.value : item.value}

    if(usersModified.length > 0) {

      let uid = usersModified.findIndex(user => user.id === item.id)

      if (uid !== -1) {
        usersModified[uid] = newItem
      } else {
        usersModified.push(newItem)
      }

    } else {
      usersModified.push(newItem)
    }


   gsap.to(button, {
      opacity: 0,
      duration: 0.3,
      onComplete: (e) => {
        this.props.editMirroName(newItem)
        this.setState({ canEdit: false })
        gsap.from('.js-edit-button', {
          opacity: 0,
          duration: 1,
          onComplete: (e) => {
          }
        })
      }
    })

  }

  _optionOnClick(e, item) {

    let currentTarget = e.target
 
    if (currentTarget.nodeName === 'DIV') {
      if(usersModified.length > 0) {

        let uid = usersModified.findIndex(user => user.id === item.id)

        if (uid !== -1) {
          this.setState({canEdit: false})
          this._selectOption(e, usersModified[uid], this.state)
        } else {
          this.setState({canEdit: false})
          this._selectOption(e, item, this.state)
        }

      } else {

        if (this.props.disabledOptions && this.props.disabledOptions.includes(item.id)) {
          return ""
        } else {
          this.setState({canEdit: false})
          this._selectOption(e, item, this.state)
        }
        
      }

    } 
  }

    // @Option type input



    render() {
      return (
        <div
          ref={(ref) => this.props.setRef(ref)}
          className={
            this.props.colorGrey
            ? "wrapper-multiple-select grey"
            : this.props.noBorder
            ? "wrapper-multiple-select no-border"
            : "wrapper-multiple-select"
        }
      >
        <p className="label-multiple-select">
          {this.props.label && this.props.label}
          {
            this.props.required &&
            <span className="asterisk">*</span>
          }
          
        </p>

        <div className={this.props.hasTooltip ? "has-tooltip" : ""}>
          <div
            ref={(el) => (this.containerElement = el)}
            className="container-multiple-select"
            onClick={(e) => {
              this.props.options !== undefined &&
                this.props.options.length > 0 &&
                this._openOptionsModal(e)
            }}
          >
            <p className="text-multiple-select">{this.props.defaultValue}</p>
            {!this.props.disabled && (
              <div
                ref={(el) => (this.iconElement = el)}
                className="icons-multiple-select"
              >
                <Icon
                  className={"icon"}
                  noWrapper={true}
                  name="SingleArrow"
                  orientation="down"
                  width="20px"
                  height="20px"
                />
              </div>
            )}
          </div>

          <div
            ref={(el) => (this.optionsElement = el)}
            className={`${
              this.props.label
                ? "options-multiple-select"
                : "options-multiple-select top"
              } 
              ${this.props.hasTooltip ? "with-helper" : ""}
              ${this.props.optionsNoAbsolute ? "no-absolute" : ""}`
            }
          >

            {
              this.props.options !== undefined &&
                !this.multiple &&
                this.props.hasInput &&
                this.props.options.map((item, index) => {
                  if (item.value !== "Select") {
                    return (
                      <div 
                        className='options-multiple-select__input-container'
                        onClick={(e) => this._optionOnClick(e, item)}
                      >
                        <input 
                          onChange={(e) => this._inputOnchange(e, index)}
                          value={this._inputValue(item, index)}
                          disabled={true}
                          className={
                            this.state.inputValue.index === index  &&
                            this.state.inputValue.value !== '' &&
                            this.state.canEdit 
                              ? 'options-multiple-select__input is-active' 
                              : 'options-multiple-select__input'
                          }
                        />

                        {
                          this.state.inputValue.index === index  &&
                          this.state.inputValue.value !== '' &&
                            this.state.canEdit &&
                            <button 
                              ref={this.checkIconButton}
                              type='button' 
                              onClick={(e) =>  this._confirmUserChanged(e, item, this.state)}
                              className='js-check-button'
                            >
                              <Icon
                                name="Check"
                                color="#408197"
                                className='options-multiple-select__icon'
                                iconBig={false}
                                width={16}
                                height={16}
                              />
                            </button>
                        }

                        {
                          !this.state.canEdit && (
                            <button 
                              ref={this.editIconButton}
                              type='button' 
                              onClick={(e) => this._editInput(e, item, index) }
                              className='js-edit-button'
                              style={{zIndex: 99999}}
                            >
                              <Icon
                                name="Edit"
                                color="#408197"
                                className='options-multiple-select__icon'
                                iconBig={false}
                                width={16}
                                height={16}
                              />
                            </button>
                          )
                        }

                      </div>
                    )

                  }
                })
            }




            {this.props.options !== undefined &&
                !this.props.hasInput &&
              this.props.options.map((item, index) => {
                if (item.value !== "Select") {
                  return (
                    <p
                      key={`option_${index}`}
                      className="option"
                      style={{
                        color:
                          this.props.disabledOptions &&
                          this.props.disabledOptions.includes(item.id)
                            ? "gray"
                            : "",
                      }}
                      onClick={(e) =>
                        this.props.disabledOptions &&
                        this.props.disabledOptions.includes(item.id)
                          ? ""
                          : this._selectOption(e, item, this.state)
                      }
                    >
                      {this.props.language
                        ? this._getLanguageLegible(item.value)
                        : this.props.asName ? item[this.props.asName] : item.value
                      }
                      {this.state.items !== undefined &&
                        this.props.multiple &&
                        this.state.items.filter(
                          (element) => element.value === item.value
                        ).length > 0 && (
                          <Check width={10} height={10} color={"#408197"} />
                        )}
                    </p>
                  );
                }
              })}
          </div>

          {this.props.hasTooltip && (
            <span
              className="wrapper-helper"
              onClick={(e) => this._handleTooltip(e)}
            >
              <div className="helper-text">
                <Icon name="InfoTooltip" noWrapper={true}/>
              </div>

              {this.state.showTooltip && (
                <div
                  className="container-tooltip right"
                  onClick={(e) => this._handleTooltip(e)}
                >
                  <span className="tooltip-title">
                    {this.props.helperTooltip[0]}
                  </span>
                  <span className="tooltip-text" dangerouslySetInnerHTML={{__html: this.props.helperTooltip[1]}}>
                  </span>
                </div>
              )}
            </span>
          )}
        </div>

        {/* Error */}
        {this.props.showErrorLabelNow && this.props.showErrorLabel && (
          <span className="label error">{this.props.errorLabel}</span>
        )}
        {/* @Error */}

        {/* Error format */}
        {this.props.showFormatError && (
          <span className="label error">{this.props.showFormatErrorLabel}</span>
        )}
        {/* @Error format */}

        {/* Hint */}
        {this.props.showHint && (
          <span className="label hint">{this.props.hint}</span>
        )}
        {/* @Hint */}
        {/* specialHint */}
        {this.props.specialHint && this.props.specialHint[0] && (
          <div class="hint-special">
          <span className="label label--highlighted" dangerouslySetInnerHTML={{__html: this.props.specialHint[1]}}>
          </span>
          <Icon
            name="Close"
            color="white"
            iconBig={false}
            className="wrapper-icon-floatButton small"
            close={false}
            width={5}
            height={5}
            />
            </div>
        )}
        {/* @specialHint */}

      </div>
    );
  }
}

export default SelectMultiple;
