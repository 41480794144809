import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { gsap } from "gsap";

import Icon from "../components/icons/";

let posX = 0;

const Tabs = (props) => {
  const [step, setStep] = useState(props.tabActive ? props.tabActive : 0);
  const [showScrollArrows, setShowScrollArrows] = useState(false);
  const [showScrollArrowsTwo, setShowScrollArrowsTwo] = useState(false);
  const [children, setChildren] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const tabRefs = useRef([]);

  const wrapperRef = useRef(null);
  const containerRef = useRef(null);
  const wrapperTwoRef = useRef(null);
  const containerTwoRef = useRef(null);
  const tabsArrowRight = useRef(null);
  const tabsArrowLeft = useRef(null);
  const tabsArrowRightTwo = useRef(null);
  const tabsArrowLeftTwo = useRef(null);

  useEffect(() => {
    checkWidth();
    setTimeout(() => centerTab(), 500);
  }, []);
  

  useEffect(() => {
    setTimeout(() => checkWidth(), 2000);
  }, [props.date || props.tabs])

  useEffect(() => {
    if(props.restartChild === true){
      let tab  = props.tabs.filter(t => t.code === "asset_allocation")[0]
      if(tab){
        // tab.children[0]
        // (tab, index, isChildred = false) 
        onClick(tab.children[0], 0, true)
      }
    }
  }, [props.restartChild]);

  useEffect(() => {
    setStep(props.tabActive);

    if (props.tabActive < 0) {

      setChildren([]);

      props.tabs.forEach((tab, index) => {
        tab.open = false;
        tabRefs.current[index].classList.remove("is-active");
      })
      
    }
  }, [props.tabActive]);

  useEffect(() => {
    if(children.length > 0){
      setRefresh(!refresh)
      let tab = props.tabs.filter((t) => t.code === "asset_allocation")[0]
      if(tab){
        // handleChildren(tab, 2)  
        setChildren(tab.children)  
      }
      
    }
  }, [props.tabs]);


  const _getUrlVars = () => {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  };

  const onClick = (tab, index, isChildred = false) => {
    if(!tab.isParent && !tab.isChild) {
      tabRefs.current.map((el, i) => {
        if(el && el.dataset && el.dataset.isparent) {
          el.classList.remove("is-active");
          props.tabs[el.dataset.id].open = false;
          setChildren([]);
        }
      });

    }

    // setTabActive(tabRefs.current[index]);
    setStep(tab.code);
    let url = window.location.href.split("&")[0];
    let prepare_search = []
    prepare_search.push("code="+tab.code)
    if (_getUrlVars()["id"] !== "" && _getUrlVars()["id"] !== undefined){
      prepare_search.push("id="+_getUrlVars()["id"])
    }
    if (props.date !== "" && props.date !== undefined && props.date !== null && props.date !== "null"){
      prepare_search.push("historical_date="+props.date)
    }
    prepare_search = "&"+prepare_search.join("&")
    window.history.replaceState({}, "bt360", `${url}${prepare_search}`);
    props.onClick(tab, index, isChildred);
  };


  const handleChildren = (tab, index) => {
    // cleanActiveTab();

    if (children.length > 0) {
      // tabRefs.current[index].classList.remove("is-active");
      setChildren([]);
      // tab.open = false;
    } else {
      setStep(tab.code);
      // Sending children 0 to activate first tab of secondary tabs
      props.onClick(tab, index, false);

      tabRefs.current[index].classList.add("is-active");
      setChildren(tab.children);
      // tab.open = true;
    }

    setTimeout(() => {
      checkWidth();
    }, 1000)

    props.onClickChildren && props.onClickChildren(tab, index);
  };

  const centerTab = () => {
    // if tab clicked is inside tab container
    // so it is not a dropdown tab
    if (props.tabActive <= props.tabs.length - 1) {
      let container = containerRef.current;
      if (container) {
        let containerLeft = container.getBoundingClientRect().left;
        let containerWidth = container.getBoundingClientRect().width;
        let tabActive = container.querySelector(".c-tabs.is-active");

        if (tabActive) {
          let leftPos = tabActive.offsetLeft;
          let width = tabActive.offsetWidth;
          let tabActivePos = leftPos + width;

          if (tabActivePos > containerWidth || tabActivePos < 0) {
            // let pos = containerLeft + (containerWidth - tabActivePos)
            let pos = containerLeft + (containerWidth - tabActivePos - 500);
            let limit = getTabsWidth().primary - containerWidth + 48;
            pos = Math.max(-limit, Math.min(0, pos));
            container.style.transform = `matrix3d(1,0,0,0,0,1,0,0,0,0,1,0,${pos},0,0,1)`;
            showHideArrows(pos);
          }
        }
      }
    }
  };

  const tabsClassesHandler = () => {
    const cl = classNames(
      "c-tabs__container",
      props.className && props.className,
      showScrollArrows && "has-arrows",
    );
    return cl;
  };

  const tabsWrapperClassesHandler = () => {
    const cl = classNames(
      "c-tabs__wrapper", 
      props.classNameWrapper && props.classNameWrapper,
      props.hasHistorical && props.date !== '' && props.date !== null && props.date !== "null" && "is-highlighted",
      props.isParent && "c-tabs__wrapper--parent",
    );
    return cl;
  };

  const tabsWrapperChildrenClassesHandler = () => {
    const cl = classNames(
      "c-tabs__wrapper",
      "c-tabs__wrapper--children",
      props.classNameWrapper && props.classNameWrapper,
      props.hasHistorical && props.date !== '' && props.date !== null && props.date !== "null" && "is-highlighted",
    );
    return cl;
  };

  const tabsArrowRightClassesHandler = () => {
    const cl = classNames(
      "c-tabs__arrows c-tabs__arrows--right",
      props.classNameArrowRight && props.classNameArrowRight,
    );
    return cl;
  };

  const tabsArrowLeftClassesHandler = () => {
    const cl = classNames(
      "c-tabs__arrows c-tabs__arrows--left",
      props.classNameArrowLeft && props.classNameArrowLeft,
    );
    return cl;
  };

  const tabChevronArrow = (tab) => {
    const cl = classNames(
      "c-tabs__text-chevron", 
      (children.length > 0) && "c-tabs__text-chevron--open"
    );

    return cl;
  };

  const getTabsWidth = () => {
    // Primary Tabs
    let tabs = [...containerRef.current.querySelectorAll(".c-tabs")];
    let sumAllTabsWidth = tabs.reduce((prev, curr) => prev + curr.offsetWidth, 0);

    // Seconday Tabs
    let sumAllTabsTwoWidth = 0;
    if(containerTwoRef.current) {
      let tabsTwo = [...containerTwoRef.current.querySelectorAll(".c-tabs")];
      sumAllTabsTwoWidth = tabsTwo.reduce((prev, curr) => prev + curr.offsetWidth, 0);
    }
    return {primary: sumAllTabsWidth, secondary: sumAllTabsTwoWidth};
  };

  const checkWidth = () => {
    // Primary
    if(containerRef.current == null){
      return
    }
    if(getTabsWidth().primary > wrapperRef.current.offsetWidth) {
     setShowScrollArrows(true);
    }else {
      setShowScrollArrows(false);
      containerRef.current.style.transform = `matrix3d(1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,1)`;
    }

    // Secondary
    if(wrapperTwoRef.current) { 
      if(getTabsWidth().secondary > wrapperTwoRef.current.offsetWidth) {
        setShowScrollArrowsTwo(true);
      }else {
        setShowScrollArrowsTwo(false);
        containerTwoRef.current.style.transform = `matrix3d(1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,1)`;
      }
    }
  };


  const arrowClick = (orientation, level) => {
    let containerWidth = containerRef.current.offsetWidth;
    let limit = getTabsWidth().primary - containerWidth + 48;

    if (orientation === "left") {
      posX += 176;
    } else {
      posX -= 176;
    }

    posX = Math.max(-limit, Math.min(0, posX));

    if(level === 1) {
      containerRef.current.style.transform = `matrix3d(1,0,0,0,0,1,0,0,0,0,1,0,${posX},0,0,1)`;
    }else {
      containerTwoRef.current.style.transform = `matrix3d(1,0,0,0,0,1,0,0,0,0,1,0,${posX},0,0,1)`;
    }
    showHideArrows(posX, level);
  };

  const showHideArrows = (posX, level) => {

    if(level === 1) {
      // PRIMARY
      let containerWidth = containerRef.current.offsetWidth;
      let limit = getTabsWidth().primary - containerWidth + 48;
      if (posX < 0) {
        tabsArrowLeft.current.style.opacity = 1;
        tabsArrowLeft.current.style.pointerEvents = "all";
      } else {
        tabsArrowLeft.current.style.opacity = 0;
        tabsArrowLeft.current.style.pointerEvents = "none";
      }

      if (posX === -limit) {
        tabsArrowRight.current.style.opacity = 0;
        tabsArrowRight.current.style.pointerEvents = "none";
      } else {
        tabsArrowRight.current.style.opacity = 1;
        tabsArrowRight.current.style.pointerEvents = "all";
      }
    } else {
      // SECONDARY
      let containerWidthTwo = containerTwoRef.current.offsetWidth;
      let limit = getTabsWidth().secondary - containerWidthTwo + 48;
      if (posX < 0) {
        tabsArrowLeftTwo.current.style.opacity = 1;
        tabsArrowLeftTwo.current.style.pointerEvents = "all";
      } else {
        tabsArrowLeftTwo.current.style.opacity = 0;
        tabsArrowLeftTwo.current.style.pointerEvents = "none";
      }

      if (posX === -limit) {
        tabsArrowRightTwo.current.style.opacity = 0;
        tabsArrowRightTwo.current.style.pointerEvents = "none";
      } else {
        tabsArrowRightTwo.current.style.opacity = 1;
        tabsArrowRightTwo.current.style.pointerEvents = "all";
      }

    }

  };
  return (
    <>

      {/*  PRIMARY TAB  */}
      <div className={tabsWrapperClassesHandler()} ref={wrapperRef}>
        <div className={tabsClassesHandler()} ref={containerRef}>
          {props.tabs.map((tab, index) => {
            return (
              <div
                ref={(el) => (tabRefs.current[index] = el)}
                key={`${index}-indexTabs`}
                data-isparent={tab.isParent}
                data-id={index}
                onClick={
                  tab.isParent === true ? () => {
                    handleChildren(tab, index) }
                    : (e) => onClick(tab, index)
                }
                className={
                  tab.isParent === true ? `c-tabs` : `c-tabs ${step === tab.code || step === index ? "is-active" : ""}`
                }
              >
                <span className="c-tabs__text">{tab.title}</span>
                {tab.isParent && (
                  <Icon name="ChevronArrow" orientation="down" className={tabChevronArrow(tab)} />
                )}
              </div>
            );
          })}
        </div>
        {showScrollArrows && (
          <>
            <div
              ref={tabsArrowRight}
              className={tabsArrowRightClassesHandler()}
              onClick={() => arrowClick("right", 1)}
            >
              <Icon name="ChevronArrow" orientation="right" noWrapper={true} />
            </div>

            <div
              ref={tabsArrowLeft}
              className={tabsArrowLeftClassesHandler()}
              onClick={() => arrowClick("left", 1)}
            >
              <Icon name="ChevronArrow" orientation="left" noWrapper={true} />
            </div>
          </>
        )}
      </div>

      {/*  SECONDARY TAB  */}
      {children.length > 0 && (
        <div className={tabsWrapperChildrenClassesHandler()} ref={wrapperTwoRef}>
          <div className={tabsClassesHandler()} ref={containerTwoRef}>
            {children.map((tab, index) => {
              return (
                <div
                  key={`${index}-indexTabs-children`}
                  onClick={(e) => onClick(tab, index, true)}
                  className={`c-tabs ${index === props.tabActiveChildren ? "is-active" : ""}`}
                >
                  <span className="c-tabs__text">{tab.title}</span>
                </div>
              );
            })}
          </div>
          {showScrollArrowsTwo && (
            <>
              <div
                ref={tabsArrowRightTwo}
                className={tabsArrowRightClassesHandler()}
                onClick={() => arrowClick("right", 2)}
              >
                <Icon name="ChevronArrow" orientation="right" noWrapper={true} />
              </div>

              <div
                ref={tabsArrowLeftTwo}
                className={tabsArrowLeftClassesHandler()}
                onClick={() => arrowClick("left", 2)}
              >
                <Icon name="ChevronArrow" orientation="left" noWrapper={true} />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Tabs;
